.root {
    text-align: center;
}
.container {
    font-size: 14px;
    position: fixed;
    top: 40%;
    left: 50%;
    height: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.header h2 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
}
.content {
    background-color: rgb(205, 205, 205);
    border-radius: 20px;
    position: relative;
    width: 80vw;
}
.contentTop {
    padding: 20px 0px 0px 0px;
}
.contentBottom {
    padding: 0px 0px 20px 0px;
}
.prompt {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
}
.buttonWrap {
    display: flex;
    justify-content: space-around;
}

/* 这个 height, width 必须和 SpinLoading 组件的 size 相同 */
.spinLoading {
    height: 96px;
    width: 96px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 20%;
}
