/* 新版本的华为荣耀pad */
/* width: 1143 */
/* height: 662 */

/* main 和 footer 之间由 10像素的 margin */
/* 两边有20像素, 所以: */
/* width: 1143-20 = 1123 */
/* height: 662-20 = 642 */

/* 有机情况测试发现最佳比例如下 */
/* width: 1098*/
/* height: 624*/

.container {
    width: 1118px; /*  版心的总宽度 */
    margin: 0 auto; /* 左右居中对齐 */
    /* border: 5px solid black; */
}

/* 头部 start */
/* 高: 624/12*2 = 104*/
.header {
    height: 104px;
    text-align: center;
    margin-top: 7px;
    /* background-color: tomato; */
    /* border: 5px solid tomato; */
}
/* 宽: (1118-20)/3*1 = 366 */
.header .logo {
    width: 366px;
    margin: 0 auto;
    /* background-color: tomato; */
}
/* 这里建议限制下高度,要不然加载不出来图片,这个排版都可能会乱 */
.header .logo img {
    width: 260px;
    /*height: 94px;*/
    padding-top: 10px;
    padding-left: 10px;
}
/* 宽: (1118-20)/3*1 = 366 */
.header .room {
    width: 366px;
    height: 104px;
    line-height: 104px;
    font-size: 40px;
    font-weight: bold;
    margin: 0 10px;
    /* background-color: skyblue; */
}
/* 宽: (1118-20)/3*1 = 366 */
.header .datetime {
    width: 216px; /* width + padding-left + padding-right = 366 */
    padding-left: 150px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    /* background-color: aquamarine; */
}
.header .datetime li {
    height: 37px;
    line-height: 37px;
    font-size: 18px;
}

/* 头部 end */

/* 高 624/12*9 = 468*/
.main {
    height: 468px;
    text-align: center;
    /* background-color: 'skyblue'; */
    /* border: 5px solid blue; */
}
/* 宽: (1118-20)/4*1 = 274.5*/
/* 高: 468/2 = 234 */
.main .meetingInfo .current {
    width: 284.5px; /* 本来是 274.5+20margin 的,现在是 284.5+10margin */
    height: 224px;
    background-color: rgb(239, 239, 239);
    margin-bottom: 10px;
    margin-right: 10px;
    border-top-left-radius: 16px;
}
.main .meetingInfo .current .title {
    background-color: rgb(72, 122, 171);
    font-size: 30px;
    font-weight: bold;
    color: white;
    height: 52px;
    line-height: 52px;
    border-top-left-radius: 16px;
}
.main .meetingInfo .current .content {
    background-color: rgb(239, 239, 239);
    margin-top: 5px;
}
.main .meetingInfo .current .content .item1 {
    height: 46px;
    font-size: 1.3em;
    font-weight: bold;
    margin: 0px 60px;
    margin-bottom: 5px;

    /* 尾部超出范围隐藏 */
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.main .meetingInfo .current .content .item2 {
    text-align: left;
    font-size: 1.1em;
    margin: 0px 60px;
    height: 60px;

    /* 尾部超出范围隐藏 */
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.main .meetingInfo .current .roomIdle {
    height: 164px;
    line-height: 164px;
    font-size: 60px;
    font-weight: bold;
    color: rgb(174, 61, 46);
    box-sizing: border-box;
}
.main .meetingInfo .current .status {
    /*margin-top: 10px;*/
    height: 40px;
    line-height: 40px;
    font-size: 30px;
    font-weight: bold;
    color: rgb(174, 61, 46);
}
/* 宽: (1118-20)/4*1 = 274.5*/
/* 高: 468/2 = 234 */
.main .meetingInfo .qrcode {
    width: 284.5px; /* 本来是 274.5+20margin 的,现在是 284.5+10margin */
    height: 234px;
    background-color: rgb(239, 239, 239);
    margin-right: 10px;
}
.main .meetingInfo .qrcode .title {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding-top: 15px;
}
.main .meetingInfo .qrcode .title .text {
    font-size: 1.8em;
    font-weight: bold;
}
.main .meetingInfo .qrcode .title .button {
    font-size: 1.4em;
    padding: 4px;
    background-color: rgb(60, 112, 159);
}
.main .meetingInfo .qrcode img {
    margin: 0 auto;
    margin-top: 10px;
    height: 170px;
}

/* 宽: (1118-20)/4*3 = 823.5 */
/* 高 624/12*9 = 468*/
.main .meetingList {
    width: 823.5px;
    height: 468px;
    background-color: rgb(239, 239, 239);
    border-top-right-radius: 16px;
}
.main .meetingList caption {
    height: 52px;
    line-height: 52px;
    background-color: rgb(72, 122, 171);
    font-size: 30px;
    font-weight: bold;
    color: white;
    border-top-right-radius: 16px;
}
/* https://stackoverflow.com/questions/4457506/set-the-table-column-width-constant-regardless-of-the-amount-of-text-in-its-cell */
.main .meetingList table {
    width: 823.5px;
    height: 416px; /* 需要减去 caption 高度: 468-52=416 */
    border-collapse: collapse;
    /* 固定每一列的宽度 */
    /* table-layout: fixed; */
}
.main .meetingList thead tr {
    height: 52px;
    font-size: 1.2rem;
}
.main .meetingList tbody tr {
    height: 52px;
    font-size: 0.9rem;
}
.main .meetingList tbody tr:nth-child(2n - 1) {
    background-color: rgb(190, 190, 190);
}
.main .meetingList tbody tr:nth-child(2n) {
    background-color: rgb(239, 239, 239);
}
.main .meetingList th,
.main .meetingList td {
    border-style: hidden;
    /* 每一列的长度太长就隐藏掉 */
    overflow: hidden;
    padding: 0 5px;
    user-select: none;
}
.main .meetingList td:nth-child(1) {
    /* 会议时间 */
    width: 100px;
}
.main .meetingList td:nth-child(2) {
    /* 会议主题 */
    max-width: 523px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.main .meetingList td:nth-child(3) {
    /* 预约部门 */
    width: 100px;
}
.main .meetingList td:nth-child(4) {
    /* 预定人 */
    width: 100px;
}

/* 宽: (1118-20)/4*3 = 823.5 */
/* 高: 624/12*1 = 52 */
.footer {
    height: 52px;
    text-align: center;
    /* background-color: 'aquamarine'; */
}

.footer .marquee {
    width: 1118px;
    height: 62px;
    line-height: 62px;
    margin-top: 10px;
    font-size: 20px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;

    /* background-color: rgb(138, 43, 266); */
    background-color: rgb(190, 190, 190);
}
