.formContainer {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    width: 500px;
    height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    z-index: 100;
}

.loginTitle {
    text-align: center;
    height: 80px;
    line-height: 80px;
    font-size: 30px;
    color: white;
}
