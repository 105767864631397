.container {
    width: 1001px; /*  版心的总宽度 */
    margin: 0 auto; /* 左右居中对齐 */
}

/* 头部 start */
/* 高: 744/12*2 = 124 */
.header {
    height: 124px;
    text-align: center;
}
/* 宽: (1001-20)/3*1 = 327 */
.header .logo {
    width: 327px;
    margin: 0 auto;
    /*background-color: tomato;*/
}
/* 这里建议限制下高度,要不然加载不出来图片,这个排版都可能会乱 */
.header .logo img {
    width: 260px;
    /*height: 94px;*/
    padding-top: 30px;
}
/* 宽: (1001-20)/3*1 = 327 */
.header .room {
    width: 327px;
    height: 124px;
    line-height: 124px;
    font-size: 40px;
    font-weight: bold;
    margin: 0 10px;
    /*background-color: skyblue;*/
}
/* 宽: (1001-20)/3*1 = 327 */
.header .datetime {
    width: 177px;
    /* width: 327; */
    text-align: right;
    padding-left: 150px;
    padding-top: 30px;
    padding-bottom: 30px;
    /*background-color: aquamarine;*/
}
.header .datetime li {
    height: 32px;
    line-height: 32px;
    font-size: 18px;
}

/* 头部 end */

/* 高 744/12*9 = 558 */
.main {
    height: 558px;
    text-align: center;
}
/* 宽: (1001-20)/3*1 = 327 */
.main .meetingInfo .current {
    width: 317px;
    height: 274px;
    background-color: rgb(239, 239, 239);
    margin-bottom: 10px;
    margin-right: 10px;
    border-top-left-radius: 16px;
}
.main .meetingInfo .current .title {
    background-color: rgb(72, 122, 171);
    font-size: 30px;
    font-weight: bold;
    color: white;
    height: 60px;
    line-height: 60px;
    border-top-left-radius: 16px;
}
.main .meetingInfo .current .content {
    background-color: rgb(239, 239, 239);
    margin-top: 20px;
}
.main .meetingInfo .current .content .item1 {
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    margin: 0px 50px;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.main .meetingInfo .current .content .item2 {
    text-align: left;
    font-size: 16px;
    margin-left: 72px;
}
.main .meetingInfo .current .roomIdle {
    height: 214px;
    line-height: 214px;
    font-size: 60px;
    font-weight: bold;
    color: rgb(174, 61, 46);
}
.main .meetingInfo .current .status {
    /*margin-top: 10px;*/
    height: 60px;
    line-height: 60px;
    font-size: 40px;
    font-weight: bold;
    color: rgb(174, 61, 46);
}
.main .meetingInfo .qrcode {
    width: 317px;
    height: 274px;
    background-color: rgb(239, 239, 239);
    margin-right: 10px;
}
.main .meetingInfo .qrcode span {
    font-size: 20px;
    font-weight: bold;
}
.main .meetingInfo .qrcode img {
    margin: 0 auto;
    height: 254px;
}
/* 宽: (1001-20)/3*2 = 654 */
.main .meetingList {
    width: 674px;
    height: 558px;
    background-color: rgb(239, 239, 239);
    border-top-right-radius: 16px;
}
.main .meetingList caption {
    height: 60px;
    line-height: 60px;
    background-color: rgb(72, 122, 171);
    font-size: 30px;
    font-weight: bold;
    color: white;
    border-top-right-radius: 16px;
}
/* https://stackoverflow.com/questions/4457506/set-the-table-column-width-constant-regardless-of-the-amount-of-text-in-its-cell */
.main .meetingList table {
    width: 674px;
    height: 498px; /* 需要减去 caption 高度 */
    border-collapse: collapse;
    /* 固定每一列的宽度 */
    /* table-layout: fixed; */
}
.main .meetingList thead tr {
    height: 52px;
    font-size: 1.2rem;
}
.main .meetingList tbody tr {
    height: 52px;
    font-size: 0.9rem;
}
.main .meetingList tbody tr:nth-child(2n - 1) {
    background-color: rgb(190, 190, 190);
}
.main .meetingList tbody tr:nth-child(2n) {
    background-color: rgb(239, 239, 239);
}
.main .meetingList th,
.main .meetingList td {
    border-style: hidden;
    /* 每一列的长度太长就隐藏掉 */
    overflow: hidden;
    padding: 0 5px;
    user-select: none;
}
.main .meetingList td:nth-child(1) {
    /* 会议时间 */
    width: 100px;
}
.main .meetingList td:nth-child(2) {
    /* 会议主题 */
    max-width: 374px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.main .meetingList td:nth-child(3) {
    /* 预约部门 */
    width: 100px;
}
.main .meetingList td:nth-child(4) {
    /* 预定人 */
    width: 100px;
}

/* 高: 744/12*1 = 62 */
.footer {
    height: 62px;
    text-align: center;
}

.footer .marquee {
    width: 1001px;
    height: 62px;
    line-height: 62px;
    margin-top: 10px;
    font-size: 20px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;

    /*background-color: rgb(138, 43, 266);*/
    background-color: rgb(190, 190, 190);
}
